function titleChanger() {
   const sectionTitle = document.querySelector('.hub-section__title');
   const select = document.querySelector('.hub-section select');
    sectionTitle.innerHTML = select.textContent;

    select.addEventListener('change', () => {
        sectionTitle.classList.remove('animated')

        setTimeout(() => {
            sectionTitle.classList.add('animated')
            sectionTitle.innerHTML = select.textContent;
        }, 500)
    })
}
if(document.querySelector('.hub-section__title')) {
    titleChanger();
}
