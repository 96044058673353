import { followingSidebar } from '../utils/sidebar.utils.js';

if (document.querySelector('.job-search-section')) {
  const sidebar = document.querySelector('#sidebar');
  const contentSticky = document.querySelector('#content-sticky');
  const filtersBtn = document.querySelector('.filters-btn');
  const contentResult = document.querySelector('.job-search-section__result');
  const sidebarItemsArr = document.querySelectorAll(
    '.job-search-section__sidebar-item.with-accordion'
  );

  sidebarItemsArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      item.classList.toggle('active');
    });
  });

  if (sidebar && window.innerWidth >= 640) {
    followingSidebar({ sidebar, contentSticky });
  }

  if (filtersBtn) {
    filtersBtn.addEventListener('click', () => {
      filtersBtn.classList.toggle('active');
      contentSticky.classList.toggle('active');
      contentResult.classList.toggle('active');
    });

    contentResult.addEventListener('click', () => {
      if (contentResult.classList.contains('active')) {
        filtersBtn.classList.remove('active');
        contentSticky.classList.remove('active');
        contentResult.classList.remove('active');
      }
    });
  }

  const doubleRangeSliderInputs = () => {
    const doubleRange = document.getElementsByClassName(
      'double-range-inputs'
    )[0];
    if (doubleRange) {
      const slider = doubleRange.querySelector('#double-range-inputs');
      const max = +slider.dataset.max;
      const min = +slider.dataset.min;
      const step = +slider.dataset.step;
      const inputs = doubleRange.querySelectorAll('.double-range-input');
      const startValueMin = +inputs[0].value.replace(/[£,]/g, '');
      const startValueMax = +inputs[1].value.replace(/[£,]/g, '');

      noUiSlider.create(slider, {
        start: [startValueMin, startValueMax],
        connect: true,
        margin: 5,
        step: 1,
        range: {
          min: min,
          max: max,
        },
      });

      slider.noUiSlider.on('update', function (values, handle) {
        if (inputs[handle].value >= Number(max)) {
          inputs[handle].value = '£ ' + Math.round(values[handle]).toLocaleString('en-US', {
            minimumFractionDigits: 0,
          }) + '+';
        } else {
          inputs[handle].value = '£ ' + Math.round(values[handle]).toLocaleString('en-US', {
            minimumFractionDigits: 0,
          });
        }
      });

      inputs.forEach((elem, i) => {
        elem.addEventListener('input', function () {
          let value = elem.value.replace(/[£,]/g, '');

          slider.noUiSlider.set(value);
        });
      });

      slider.noUiSlider.on("end", function (values, handle) {
        if (inputs[handle].value === "£200,000") {
          inputs[handle].value = "£200,000+";
        }
        load('jobs/search', 'form:#search-form', 'form:#search-form-2', 'sort#sort'); return false;
      });
    }
  };
  doubleRangeSliderInputs();
}

const btnShowHideArr = document.querySelectorAll('.btn-show-hide');
const showHideContentArr = document.querySelectorAll('.show-hide-content');

showHideContentArr.forEach((content, i) => {
  const items = content.querySelectorAll('.meta-checkbox');
  const maxVisibleItems = content.classList.contains('with-sectors') ? 7 : 6;

  items.forEach((item, index) => {
    if (index > maxVisibleItems - 1) {
      item.classList.add('is-hidden');
    }
  });

  if (items.length > maxVisibleItems) {
    const btnShowHide = btnShowHideArr[i];
    btnShowHide.classList.add('is-visible');

    btnShowHide.addEventListener('click', () => {
      btnShowHide.classList.toggle('active');
      items.forEach((item, index) => {
        if (index > maxVisibleItems - 1) {
          item.classList.toggle('is-hidden');
        }
      });
    });
  }
});

if (document.getElementsByClassName("job-search-section__form")[0]) {
  const checkboxesTypes = document.querySelectorAll(
      'input[type="checkbox"][name="type[]"], input[type="checkbox"][name="type_second[]"]'
  );

  function syncCheckboxes(event) {
    const targetCheckbox = event.target;
    const isChecked = targetCheckbox.checked;
    const targetValue = targetCheckbox.value;

    checkboxesTypes.forEach((checkbox) => {
      if (checkbox.value === targetValue) {
        checkbox.checked = isChecked;
      }
    });
  }

  checkboxesTypes.forEach((checkbox) => {
    checkbox.addEventListener("change", syncCheckboxes);
  });

  const checkboxesSectors = document.querySelectorAll(
      'input[type="checkbox"][name="sector[]"], input[type="checkbox"][name="sector_second[]"]'
  );

  function syncCheckboxesSectors(event) {
    const targetCheckbox = event.target;
    const isChecked = targetCheckbox.checked;
    const targetValue = targetCheckbox.value;

    checkboxesSectors.forEach((checkbox) => {
      if (checkbox.value === targetValue) {
        checkbox.checked = isChecked;
      }
    });
  }

  checkboxesSectors.forEach((checkbox) => {
    checkbox.addEventListener("change", syncCheckboxesSectors);
  });
}
