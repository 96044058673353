import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const peopleSlider = document.getElementsByClassName(
  'people-section__slider'
)[0];

if (peopleSlider) {
  const peopleSlidesArr = peopleSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (peopleSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = peopleSlidesArr.length * 4;
  }*/

  const peopleSwiper = new Swiper('.people-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.people-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.people-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      901: {
        spaceBetween: 40,
      },
    },
  });

  swiperObserver(peopleSwiper);
}
