import Swiper, { Autoplay, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('creative-teams-slider')[0]) {
  const slidersArr = document.querySelectorAll('.creative-teams-slider');
  slidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const swiper = new Swiper(sliderEl, {
      modules: [Autoplay, EffectCreative],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
        },
        next: {
          effect: 'scale',
          scale: 0.55,
          translate: ['calc(80% + 10px)', 0, -1],
          opacity: 0.8,
        },
      },

      breakpoints: {
        551: {
          creativeEffect: {
            limitProgress: 2,
            prev: {
              translate: [0, 0, -320],
              opacity: 0,
            },
            next: {
              effect: 'scale',
              scale: 0.55,
              translate: ['calc(85% + 10px)', 0, -1],
              opacity: 0.8,
            },
          },
        },
      },
    });

    swiperObserver(swiper);
  });
}
