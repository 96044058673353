if (document.getElementsByClassName('job-card').length > 0) {
  const jobsCardArr = document.querySelectorAll('.job-card');
  let maxHeight = 0;

  jobsCardArr.forEach((card, i) => {
    const cardHeight = card.offsetHeight;
    if (cardHeight > maxHeight) {
      maxHeight = cardHeight;
    }
  });

  jobsCardArr.forEach((card, i) => {
    card.style.minHeight = maxHeight + 'px';
  });
}
