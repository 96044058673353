import Swiper, {Autoplay, Grid, Navigation} from 'swiper';

window.initStatsPopup = function initStatsPopup() {
  const statsPopup = document.getElementsByClassName('stats-popup')[0];
  const statsCircle = statsPopup.getElementsByClassName('popup__stats-circle')[0];
  const statsItems = statsPopup.querySelectorAll('.popup__stats-item');

  if(statsCircle) {
    const statsPercentage = statsCircle.querySelector('.percentage');
    const statsPercentageText = statsCircle.querySelector('.circle-text span');

    const statsSlider = document.getElementsByClassName('popup__stats-slider')[0];
    const jobsSlider = document.getElementsByClassName('popup__jobs-slider')[0];

    const infoContent = statsPopup.querySelector('.popup__info-content');
    const statsInfo = statsPopup.querySelector('.popup__stats-info');
    const statsScroll = statsPopup.querySelector('.popup__stats-scroll');

    let percentStartCount = 0;
    let percentEndCount = percentToDecimal(statsPercentageText.textContent);
    statsPercentageText.textContent = '';

    const duration = durationValue();

    if(window.innerWidth >= 768 && infoContent.clientHeight >= 500) {
      statsScroll.style.maxHeight = `${infoContent.clientHeight - statsInfo.clientHeight}px`;
    }

    if(window.innerWidth >= 768) {
      new SimpleBar(document.querySelector(".popup__stats-scroll"));
    }

    const bar = new ProgressBar.Circle(statsPercentage, {
      color: 'url(#gradient)',
      trailColor: '#1A1A1A',
      trailWidth: 50,
      duration: duration,
      strokeWidth: 50,

    });

    bar.animate(percentEndCount / 100);

    const linearGradient = `
  <defs>
    <linearGradient id="gradient" x1="50%" y1="100%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
      <stop offset="10%" stop-color="#8de8fc"/>
      <stop offset="100%" stop-color="#7f3bb1"/>
    </linearGradient>
  </defs>
    `;
    bar.svg.insertAdjacentHTML('afterBegin', linearGradient);

    /*updateTransform(percentEndCount);

    function updateTransform(percent) {
      const circleElement = statsPercentage.getElementById('bar');

      const radius = parseFloat(circleElement.getAttribute('r'));
      const circumference = Math.PI * (radius * 2);

      const strokeDashOffset = ((100 - percent) / 100) * circumference;

      circleElement.style.strokeDashoffset = strokeDashOffset + 'px';
    }*/

    function animateValue(item, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = (progress * (end - start) + start).toFixed(2);
        item.textContent = decimalToPercent(value);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }

    animateValue(statsPercentageText, percentStartCount, percentEndCount, duration);

    function durationValue() {
      const minDuration = 300;
      const maxDuration = 1200;

      const range = Math.abs(percentEndCount - 0);
      return Math.min(maxDuration, minDuration + range * 20);
    }

    function percentToDecimal(percent) {
      const cleanPercent = percent.replace('%', '').replace(',', '.');
      return parseFloat(cleanPercent);
    }

    function decimalToPercent(decimal) {
      return (parseFloat(decimal)).toFixed(2).replace('.', ',') + "%";
    }

    const percentString = decimalToPercent(30.55);
    console.log(percentString);

    const decimalNumber = percentToDecimal("30,55%");
    console.log(decimalNumber);


    if (statsSlider) {
      let statsSwiper;

      function initStatsSwiper() {
        statsSwiper = new Swiper(statsSlider, {
          modules: [Autoplay, Navigation, Grid],
          slidesPerView: 1,
          spaceBetween: 8,
          threshold: 10,
          grid: {
            rows: 3,
          },
          /*slideToClickedSlide: true,*/
          navigation: {
            prevEl: statsSlider.querySelector('.slider-buttons .slider-btn.prev'),
            nextEl: statsSlider.querySelector('.slider-buttons .slider-btn.next'),
          },
        });
      }

      function handleStatsResize() {
        if (window.innerWidth < 768) {
          initStatsSwiper();
        } else {
          if (statsSwiper) {
            statsSwiper.destroy();
          }
        }
      }

      initStatsSwiper();
      handleStatsResize();

      window.addEventListener('resize', handleStatsResize);
    }

    if (jobsSlider) {
      let jobsSwiper;

      function initJobsSwiper() {
        jobsSwiper = new Swiper(jobsSlider, {
          modules: [Autoplay, Navigation],
          slidesPerView: 1,
          spaceBetween: 8,
          threshold: 10,
          /*autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },*/
          slideToClickedSlide: true,
          navigation: {
            prevEl: jobsSlider.querySelector('.slider-buttons .slider-btn.prev'),
            nextEl: jobsSlider.querySelector('.slider-buttons .slider-btn.next'),
          },
          /*breakpoints: {
            651: {
              spaceBetween: 8,
              slidesPerView: 1.5,
            },
            901: {
              spaceBetween: 8,
              slidesPerView: 2,
            },
          },*/
        });
      }

      function handleJobsResize() {
        if (window.innerWidth < 768) {
          initJobsSwiper();
        } else {
          if (jobsSwiper) {
            jobsSwiper.destroy();
          }
        }
      }

      initJobsSwiper();
      handleJobsResize();

      window.addEventListener('resize', handleJobsResize);
    }
  }
};