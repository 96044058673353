import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const whatSlider = document.getElementsByClassName('what-section__slider')[0];

if (whatSlider) {
  const ourValuesSlidesArr = whatSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (gallerySlidesArr.length >= 4) {
    loop = true;
    loopedSlides = gallerySlidesArr.length * 4;
  }*/

  const whatSwiper = new Swiper('.what-section__slider', {
    modules: [Autoplay, Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    pagination: {
      el: '.what-slider-buttons .swiper-pagination',
      type: 'fraction',
      formatFractionCurrent: function (number) {
        return number;
      },
      formatFractionTotal: function (number) {
        return number;
      },
    },
    navigation: {
      prevEl: '.what-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.what-slider-buttons.slider-buttons .slider-btn.next',
    },
  });

  /* swiperObserver(whatSwiper);*/
}
