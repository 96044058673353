import './utils/vh.utils.js';
import './utils/safari.utils.js';

import './utils/animate-on-view.utils.js';
import './utils/parallax-on-view.utills.js';
import './utils/accordion.utills.js';

import './components/header.component.js';
import './components/buttons.component.js';
import './components/animations.component.js';
import './components/jobs.component.js';
import './components/jobs-search.component.js';

/*import './components/uploadFile.component.js';*/
import './components/inputs.component.js';
import './components/calculator.component.js';
import './components/titleChanger.component.js';
/*import './components/specialized.component.js';*/

/*Sliders*/
import './components/gallery.component.js';
import './components/our-values.component.js';
import './components/teams.component.js';
import './components/resources.component.js';
import './components/what.component.js';
import './components/we.component.js';
import './components/people.component.js';
import './components/creative-teams.component.js';
import './components/together.component.js';
import './components/recruitment.component.js';
import './components/recruitment-career.component.js';
import './components/people-second.component.js';
import './components/roles.component.js';
import './components/list-slider.component.js';
/*End sliders*/

import './components/stats-popup.component.js';

import './libs/countUp.lib.js';

/*var popupFD = document.querySelector('#popup');
if (popupFD) {
  /!*var popupTitle = popupFD.querySelector(".popup__title");*!/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    console.log(parentBlock);
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const scrollContentElement = document.querySelector('.popup__content');
    console.log(scrollContentElement);

    if (scrollContentElement) {
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    singleCalcRangePopupSlider();
  };
}

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};*/

/*document.querySelectorAll('video[autoplay]').forEach(function (video) {
  video.play();
});*/
