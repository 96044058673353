import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const weSlider = document.getElementsByClassName('we-section__slider')[0];

if (weSlider) {
  const ourValuesSlidesArr = weSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (gallerySlidesArr.length >= 4) {
    loop = true;
    loopedSlides = gallerySlidesArr.length * 4;
  }*/

  const weSwiper = new Swiper('.we-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.we-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.we-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 2.5,
      },
      901: {
        spaceBetween: 18,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
    },
  });

  console.log(weSwiper);

  swiperObserver(weSwiper);
}
