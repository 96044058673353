import Swiper, {Autoplay, Grid, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const listSlider = document.getElementsByClassName('scroll-list-section__list-slider')[0];

if (listSlider) {
  const listSlidesArr = listSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (listSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = listSlidesArr.length * 4;
  }*/

  const listSwiper = new Swiper('.scroll-list-section__list-slider', {
    modules: [Autoplay, Navigation, Grid],
    slidesPerView: 1,
    spaceBetween: 8,
    threshold: 10,
    grid: {
      rows: 3,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.list-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.list-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      451: {
        slidesPerView: 2,
      },
      651: {
        slidesPerView: 3,
      },
    },
  });

  swiperObserver(listSwiper);
}
