import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const ourValuesSlider = document.getElementsByClassName(
  'our-value-section__slider'
)[0];

if (ourValuesSlider) {
  const ourValueSectionTop = document.querySelector('.our-value-section__top');
  const ourValuesSlidesArr = ourValuesSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (gallerySlidesArr.length >= 4) {
    loop = true;
    loopedSlides = gallerySlidesArr.length * 4;
  }*/

  if(ourValuesSlidesArr.length > 4) {
    ourValueSectionTop.style.justifyContent = 'space-between';
  }

  const ourValuesSwiper = new Swiper('.our-value-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.our-value-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.our-value-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 2.5,
      },
      901: {
        spaceBetween: 18,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
    },
  });

  console.log(ourValuesSwiper);

  swiperObserver(ourValuesSwiper);
}
