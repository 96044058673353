import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const teamsSlider = document.getElementsByClassName('teams-section__slider')[0];

if (teamsSlider) {
  const teamsSlidesArr = teamsSlider.querySelectorAll('.swiper-slide');
  let loop = false;
  let loopedSlides = false;

  if (teamsSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = teamsSlidesArr.length * 4;
  }

  if(teamsSlidesArr.length <= 2) {
    teamsSlider.classList.add('with-2-slides');

    document.getElementsByClassName('teams-section__top')[0].classList.add('with-2-slides');
  }

  const teamsSwiper = new Swiper('.teams-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    loop: loop,
    loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.teams-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.teams-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 2.5,
      },
      901: {
        spaceBetween: 18,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 32,
        slidesPerView: 3,
      },
    },
  });

  console.log(teamsSwiper);

  swiperObserver(teamsSwiper);
}

if(document.getElementsByClassName('team-card')[0]) {
  setTimeout(function () {
    const teamsCardsArr = document.querySelectorAll('.team-card');
    teamsCardsArr.forEach(card => {
      card.addEventListener('click', () => {
        card.classList.toggle('is-hover');
      })
    })
  }, 100);
}
