import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const peopleSecondSlider = document.getElementsByClassName(
  'people-second-section__slider'
)[0];

if (peopleSecondSlider) {
  const peopleSecondSlidesArr =
    peopleSecondSlider.querySelectorAll('.swiper-slide');
  let loop = false;
  let loopedSlides = false;

  if (peopleSecondSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = peopleSecondSlidesArr.length * 4;
  }

  const peopleSecondSwiper = new Swiper('.people-second-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 40,
    threshold: 10,
    loop: loop,
    loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.people-second-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.people-second-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 16,
        slidesPerView: 1.2,
      },
      651: {
        spaceBetween: 18,
        slidesPerView: 1.4,
      },
      901: {
        spaceBetween: 20,
        slidesPerView: 1.5,
      },
      1025: {
        spaceBetween: 32,
        slidesPerView: 2,
      },
    },
  });

  swiperObserver(peopleSecondSwiper);
}
