import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const rolesSlider = document.getElementsByClassName('roles-section__slider')[0];

if (rolesSlider) {
  const rolesSlidesArr = rolesSlider.querySelectorAll('.swiper-slide');
  let loop = false;
  let loopedSlides = false;

  if (rolesSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = rolesSlidesArr.length * 4;
  }

  const rolesSwiper = new Swiper('.roles-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    loop: loop,
    loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.roles-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.roles-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 20,
      },
    },
  });

  swiperObserver(rolesSwiper);
}
