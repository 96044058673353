import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const resourcesSlider = document.getElementsByClassName(
  'resources-section__slider'
)[0];

if (resourcesSlider) {
  const resourcesSlidesArr = resourcesSlider.querySelectorAll('.swiper-slide');
  let loop = false;
  let loopedSlides = false;

  if (resourcesSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = resourcesSlidesArr.length * 4;
  }

  const resourcesSwiper = new Swiper('.resources-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    loop: loop,
    loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.resources-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.resources-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 2.5,
      },
      901: {
        spaceBetween: 18,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 32,
        slidesPerView: 3,
      },
    },
  });

  swiperObserver(resourcesSwiper);
}
