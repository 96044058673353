window.singleCalcRangeSlider = function singleCalcRangeSlider() {
  const calcSec = document.querySelector('.calculator-section');
  const singleCalcRange = document.getElementsByClassName(
    'single-range-calc-default'
  )[0];
  if (singleCalcRange) {
    const slider = singleCalcRange.querySelector('#single-range-calc-default');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || '£';
    const step = +slider.dataset.step;
    const percent = +slider.dataset.percent;
    const inputHidden = singleCalcRange.querySelector(
      '.single-range-calc-hidden-input'
    );
    const inputInfo = singleCalcRange.querySelector('.single-range-info');
    const startValue = +inputHidden.value;

    const input = document.querySelector(
      '.calculator-section__form-input .billings-input'
    );
    const commissionInput = document.querySelector(
        '.calculator-section__form-input .commission-input'
    );
    const listText = document.querySelector('.calculator-section__form-list');
    const listTextArr = document.querySelectorAll('.calculator-section__form-text');

    const result = document.querySelector('.calculator-section__form-sum');

    noUiSlider.create(slider, {
      start: [startValue],
      connect: 'lower',
      behaviour: 'tap',
      margin: 10,
      step: step,
      tooltips: true,
      range: {
        min: min,
        max: max,
      },
    });

    // how many percentages
    const borderLimit = 8;
    const borderDiff = 40 / borderLimit;

    const tooltip = slider.querySelector('.noUi-tooltip');
    const circle = slider.querySelector('.noUi-origin');

    /*function calculateTakeHome(billedInQuarter) {
      let accumulatedEarnings = 0;
      let topTo = 0;
      let topPercentage = 0;
      let commission;

      for (const item of permanentCommissions) {
        if (billedInQuarter >= item.from) {
          accumulatedEarnings += item.earnings;

          if (!topTo) {
            topTo = item.to || item.from;
            topPercentage = item.percentage;
          }
        }
      }

      const bonus = (topTo - billedInQuarter) * (topPercentage / 100);
      const takeHome = accumulatedEarnings - bonus;

      return takeHome;
    }*/

    function calculateCommission(amount, percentage) {
      return (amount * percentage) / 100;
    }

    function calculatePercentage(amount, commission) {
      return (commission / amount) * 100;
    }

    function returnTransform(element) {
      return element
        .replace(/[^0-9][^\d.]/g, '')
        .replace(')', '')
        .split(' ')
        .map((str) => {
          return Number(str);
        });
    }

    function formatCurrency(amount, currency) {
      const formatted = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);

      return formatted.replace(/,/g, ' ');
    }

    function formatInput(inputEl, maxValue) {
      let sanitizedValue = inputEl.value.replace(/[^\d\s]/g, '');
      sanitizedValue = sanitizedValue.replace(/[\s,]+/g, '');
      sanitizedValue = Math.min(parseInt(sanitizedValue), maxValue).toString();
      const formattedValue = sanitizedValue.replace(
        /(\d)(?=(\d{3})+(\D|$))/g,
        '$1 '
      );
      inputEl.value = '£' + formattedValue;
    }
    formatInput(input, 999999);
    formatInput(commissionInput, 400000);

    function calculatorFunc() {
      const amount = input.value.replace(/\D/g, '');
      const commission = commissionInput.value.replace(/\D/g, '');
      const commissionPercentage = inputHidden.value;
      const commissionValue = calculateCommission(amount, commissionPercentage);
      const percentageValue = calculatePercentage(amount, commission);

      inputHidden.value = percentageValue;

      if(percentageValue >= min && percentageValue <= max) {
        result.textContent = Math.round(percentageValue).toLocaleString('en-GB') + '%';
        tooltip.textContent = Math.round(percentageValue).toLocaleString('en-GB') + '%';
      }
    }

    function listTextFunc(result) {
      listTextArr.forEach((text, i) => {
        for (let j = 0; j < listTextArr.length; j++) {
          listTextArr[j].classList.remove('active');
        }

        if (result >= 0 && result < 16) {
          listTextArr[0].classList.add('active');
        } else if (result >= 16 && result < 21) {
          listTextArr[1].classList.add('active');
        } else if (result >= 21 && result < 31) {
          listTextArr[2].classList.add('active');
        } else if (result >= 31) {
          listTextArr[3].classList.add('active');
        }

        listText.classList.add('is-active');
      })
    }

    input.addEventListener('input', () => {
      if (input.value === '' || input.value === '£') {
        input.value = '£' + 0;
        formatInput(input, 999999);
        calculatorFunc();
      } else {
        formatInput(input, 999999);
        calculatorFunc();
      }

      if(inputHidden.value >= min && inputHidden.value <= max) {
        slider.noUiSlider.set(inputHidden.value);
      }
      checkVisibleHiddenValueInput();
    });

    commissionInput.addEventListener('input', () => {
      const minCommissionValue = calculateCommission(input.value.replace(/\D/g, ''), 1);
      const numCommissionValue = Number(commissionInput.value.replace(/\D/g, ''));
      if (commissionInput.value === '' || commissionInput.value === '£') {
        commissionInput.value = '£' + 0;
        formatInput(commissionInput, 400000);
        calculatorFunc();
      } else {
        formatInput(commissionInput, 400000);
        calculatorFunc();
      }

      if(Math.round(inputHidden.value) > min && Math.round(inputHidden.value) <= max) {
        if (numCommissionValue >= minCommissionValue) {
          slider.noUiSlider.set(inputHidden.value);
        } else {
          slider.noUiSlider.set(min);
        }
      } else if (Math.round(inputHidden.value) > max) {
        slider.noUiSlider.set(max);
      } else if (Math.round(inputHidden.value) === min) {
        slider.noUiSlider.set(min);
      }
      checkVisibleHiddenValueInput();
    });

    slider.noUiSlider.on('update', function (values, handle) {
      calculatorFunc();
      inputHidden.value = Math.round(values);

      let translate = returnTransform(circle.style.transform)[0];

      if (translate <= -100 + borderLimit) {
        tooltip.style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        tooltip.style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        tooltip.style.transform = 'translate(-50%,100%)';
      }

      tooltip.textContent = Math.round(values).toLocaleString('en-GB') + '%';
      result.textContent = Math.round(values).toLocaleString('en-GB') + '%';

      listTextFunc(Number(inputHidden.value));
    });

    slider.noUiSlider.on('slide', function (values, handle) {

      const commissionVal = calculateCommission(input.value.replace(/\D/g, ''), Math.round(values));
      commissionInput.value = formatCurrency(commissionVal, 'GBP');
      formatInput(commissionInput, 400000);
      checkVisibleHiddenValueInput();
    });
    function checkVisibleHiddenValueInput() {
      if(commissionInput.value === '£0') {
        if(!commissionInput.classList.contains('is-init')) {
          commissionInput.classList.add('is-init');
          commissionInput.classList.add('is-hidden-value');
          input.classList.add('is-hidden-value');
        }
      } else {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      }

      commissionInput.addEventListener('click', () => {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      })

      input.addEventListener('click', () => {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      })

      setTimeout(function () {
        calcSec.style.opacity = 1;
      }, 50);
    }
    checkVisibleHiddenValueInput();
  }
};
singleCalcRangeSlider();

window.singleCalcRangePopupSlider = function singleCalcRangePopupSlider() {
  const singleCalcRange = document.getElementsByClassName(
    'single-range-calc-popup'
  )[0];
  if (singleCalcRange) {
    const slider = singleCalcRange.querySelector('#single-range-calc-default');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || '£';
    const step = +slider.dataset.step;
    const percent = +slider.dataset.percent;
    const inputHidden = singleCalcRange.querySelector(
      '.single-range-calc-hidden-input'
    );
    const inputInfo = singleCalcRange.querySelector('.single-range-info');
    const startValue = +inputHidden.value;

    const input = document.querySelector('.calculator-popup__form-input .billings-input');
    const commissionInput = document.querySelector('.calculator-popup__form-input .commission-input');

    const listText = document.querySelector('.calculator-popup__form-list');
    const listTextArr = document.querySelectorAll('.calculator-popup__form-text');

    const result = document.querySelector('.calculator-popup__form-sum');

    noUiSlider.create(slider, {
      start: [startValue],
      connect: 'lower',
      margin: 10,
      step: step,
      tooltips: true,
      range: {
        min: min,
        max: max,
      },
    });

    // how many percentages
    const borderLimit = 8;
    const borderDiff = 40 / borderLimit;

    const tooltip = slider.querySelector('.noUi-tooltip');
    const circle = slider.querySelector('.noUi-origin');

    /*function calculateTakeHome(billedInQuarter) {
      let accumulatedEarnings = 0;
      let topTo = 0;
      let topPercentage = 0;
      let commission;

      for (const item of permanentCommissions) {
        if (billedInQuarter >= item.from) {
          accumulatedEarnings += item.earnings;

          if (!topTo) {
            topTo = item.to || item.from;
            topPercentage = item.percentage;
          }
        }
      }

      const bonus = (topTo - billedInQuarter) * (topPercentage / 100);
      const takeHome = accumulatedEarnings - bonus;

      return takeHome;
    }*/

    function calculateCommission(amount, percentage) {
      return (amount * percentage) / 100;
    }

    function calculatePercentage(amount, commission) {
      return (commission / amount) * 100;
    }

    function returnTransform(element) {
      return element
        .replace(/[^0-9][^\d.]/g, '')
        .replace(')', '')
        .split(' ')
        .map((str) => {
          return Number(str);
        });
    }

    function formatCurrency(amount, currency) {
      const formatted = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);

      return formatted.replace(/,/g, ' ');
    }

    function formatInput(inputEl, maxValue) {
      let sanitizedValue = inputEl.value.replace(/[^\d\s]/g, '');
      sanitizedValue = sanitizedValue.replace(/[\s,]+/g, '');
      sanitizedValue = Math.min(parseInt(sanitizedValue), maxValue).toString();
      const formattedValue = sanitizedValue.replace(
        /(\d)(?=(\d{3})+(\D|$))/g,
        '$1 '
      );
      inputEl.value = '£' + formattedValue;
    }
    formatInput(input, 999999);
    formatInput(commissionInput, 400000);

    function calculatorFunc() {
      const amount = input.value.replace(/\D/g, '');
      const commission = commissionInput.value.replace(/\D/g, '');
      const commissionPercentage = inputHidden.value;
      const commissionValue = calculateCommission(amount, commissionPercentage);
      const percentageValue = calculatePercentage(amount, commission);

      inputHidden.value = percentageValue;

      /*if(percentageValue >= min && percentageValue <= max) {
        result.textContent = Math.round(percentageValue).toLocaleString('en-GB') + '%';
        tooltip.textContent = Math.round(percentageValue).toLocaleString('en-GB') + '%';
      }*/
    }

    listText.classList.add('is-active');

    function listTextFunc(result) {
      listTextArr.forEach((text, i) => {
        for (let j = 0; j < listTextArr.length; j++) {
          listTextArr[j].classList.remove('active');
        }

        if (result >= 0 && result < 16) {
          listTextArr[0].classList.add('active');
        } else if (result >= 16 && result < 21) {
          listTextArr[1].classList.add('active');
        } else if (result >= 21 && result < 31) {
          listTextArr[2].classList.add('active');
        } else if (result >= 31) {
          listTextArr[3].classList.add('active');
        }

        listText.classList.add('is-active');
      })
    }

    input.addEventListener('input', () => {
      if (input.value === '' || input.value === '£') {
        input.value = '£' + 0;
        formatInput(input, 999999);
        calculatorFunc();
      } else {
        formatInput(input, 999999);
        calculatorFunc();
      }

      if(inputHidden.value >= min && inputHidden.value <= max) {
        slider.noUiSlider.set(inputHidden.value);
      }
      checkVisibleHiddenValueInput();
    });

    commissionInput.addEventListener('input', () => {
      const minCommissionValue = calculateCommission(input.value.replace(/\D/g, ''), 1);
      const numCommissionValue = Number(commissionInput.value.replace(/\D/g, ''));
      if (commissionInput.value === '' || commissionInput.value === '£') {
        commissionInput.value = '£' + 0;
        formatInput(commissionInput, 400000);
        calculatorFunc();
      } else {
        formatInput(commissionInput, 400000);
        calculatorFunc();
      }

      if(Math.round(inputHidden.value) > min && Math.round(inputHidden.value) <= max) {
        if (numCommissionValue >= minCommissionValue) {
          slider.noUiSlider.set(inputHidden.value);
        } else {
          slider.noUiSlider.set(min);
        }
      } else if (Math.round(inputHidden.value) > max) {
        slider.noUiSlider.set(max);
      } else if (Math.round(inputHidden.value) === min) {
        slider.noUiSlider.set(min);
      }
      checkVisibleHiddenValueInput();
    });

    slider.noUiSlider.on('update', function (values, handle) {
      calculatorFunc();
      inputHidden.value = Math.round(values);

      let translate = returnTransform(circle.style.transform)[0];

      if (translate <= -100 + borderLimit) {
        tooltip.style.transform = `translate(${
            -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        tooltip.style.transform = `translate(${
            -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        tooltip.style.transform = 'translate(-50%,100%)';
      }

      tooltip.textContent = Math.round(values).toLocaleString('en-GB') + '%';
      result.textContent = Math.round(values).toLocaleString('en-GB') + '%';

      listTextFunc(Number(inputHidden.value));
    });

    slider.noUiSlider.on('slide', function (values, handle) {

      const commissionVal = calculateCommission(input.value.replace(/\D/g, ''), Math.round(values));
      commissionInput.value = formatCurrency(commissionVal, 'GBP');
      formatInput(commissionInput, 400000);
      checkVisibleHiddenValueInput();
    });

    function checkVisibleHiddenValueInput() {
      if(commissionInput.value === '£0') {
        if(!commissionInput.classList.contains('is-init')) {
          commissionInput.classList.add('is-init');
          commissionInput.classList.add('is-hidden-value');
          input.classList.add('is-hidden-value');
        }
      } else {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      }

      commissionInput.addEventListener('click', () => {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      })

      input.addEventListener('click', () => {
        commissionInput.classList.remove('is-hidden-value');
        input.classList.remove('is-hidden-value');
      })
    }
    checkVisibleHiddenValueInput();
  }

  const buttons = document.querySelectorAll('.calculator-popup .btn-link');
  if (buttons.length) {
    buttons.forEach((btn) => {
      let circle = btn.querySelector('.circle');

      hoverButton(btn, circle);
    });
  }
};
